import Home from '../components/Home';
import Layout from '@/components/Layout';
Home.getLayout = function getLayout(page) {
    return (
        <Layout webViewMobile={true} web='home' >{page}</Layout>
    );
};

export default Home;


